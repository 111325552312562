var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"global-policy-create-modal","title":"Global Policy Create Modal","hide-footer":"","scrollable":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true,"centered":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Create Global Policy")])]},proxy:true}])},[_c('validation-observer',{ref:"globalpolicyCreateFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Policy Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":{ required: _vm.required, regex: /^[0-9A-Z-()#&''\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Policy Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"code","label-for":"code"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Policy Code "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"code","rules":{ required: _vm.required, regex: /^[0-9A-Z-_()#&''\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","state":errors.length > 0 ? false : null,"placeholder":"Policy code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"value","label-for":"value"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Policy Value "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"value","rules":{ required: _vm.required, regex: /^([0-9]){1,4}$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 0 ? false : null,"placeholder":"Policy value"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"description","label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Policy Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"description","rules":{ required: _vm.required, regex: /^[0-9A-Z-,.()#&''\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Policy description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1 mt-2",attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Create ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }