<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="nameFilter"
          v-model="nameFilter"
          placeholder="Search by global policy name"
        />
      </div> 
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="codeFilter"
          v-model="codeFilter"
          placeholder="Search by Policy code"
        />
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="valueFilter"
          v-model="valueFilter"
          placeholder="Search by Policy value"
        />
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="filter">
          <feather-icon icon="SearchIcon" class="mr-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="search">
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span class="align-middle">Refresh</span>
        </b-button>
      </div>

      <div class="text-right">
        <b-button
          variant="primary"
          pill
          @click="createGlobalPolicy"
          v-if="hasPermission('global_policy_create')"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Create</span>
        </b-button>
      </div>
    </div>

    <b-card>
      <b-table
        responsive
        :fields="fields"
        :items="globalpolicies"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('global_policy_show')"
        :per-page="0"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
        </template>
        <template #cell(manage)="row">
        <div class="d-flex align-items-center">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="viewGlobalPolicy(row.item)"
            v-if="hasPermission('global_policy_read')"
          >
            View
          </b-button>
          <template>
            <b-button
              variant="info"
              pill
              size="sm"
              class="mr-1"
              @click="editGlobalPolicy(row.item)"
              v-if="hasPermission('global_policy_update')"
            >
              Edit
            </b-button>
          </template>
          <!-- <template v-if="!row.item.assigned">
            <b-button
              variant="danger"
              pill
              size="sm"
              class="mr-1"
              @click="deleteGlobalPolicy(row.item)"
              v-if="hasPermission('global_policy_delete')"
            >
              Delete
            </b-button>
          </template> -->
          </div>
        </template>
      </b-table>
      <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap m-2"
      >
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </b-card>
    <GlobalPolicyCreateModal :globalPolicy="globalPolicy" @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-${globalPolicyCreateModalCount}`" />

    
      <GlobalPolicyEditModal :globalPolicy="globalPolicy" :allowEdit="allowEdit" @modalClosed="onModalClosed"
        :showModal="visible" :key="`edit-${globalPolicyEditModalCount}`" />
        
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import GlobalPolicyCreateModal from '@/components/booking/admin/GlobalPolicyCreateModal.vue';
import GlobalPolicyEditModal from '@/components/booking/admin/GlobalPolicyEditModal.vue';

export default {
  components: {
    GlobalPolicyCreateModal,
    GlobalPolicyEditModal,
  },
  data() {
    return {
      fields: [
        { key: "manage", label: "Manage" },
        //   { key: "id", label: "Global Policy ID" },
        { key: "name", label: "Name" },
        { key: "description", label: "Policy Description" },
        { key: "code", label: "Policy Code" },
        { key: "value", label: "Policy Value" },
        // { key: "created_at", label: "Created At" },
        // { key: "created_by_data", label: "Created By" },
        // { key: "updated_at", label: "Last updated" },
        // { key: "updated_by_data", label: "Updated By" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      globalPolicy: null,
      globalpolicies: [],
      globalPolicyCreateModalCount: 0,
      globalPolicyEditModalCount: 0,
      visible: false,
      totalDataLength: 0,
      nameFilter: "",
      codeFilter:"",
      valueFilter:"",
      isBusy: false,
      allowEdit: true,
    };
  },
  mixins: [util],
  async mounted() {
    // await this.search();
  },
  methods: {
    ...mapActions({
      getGlobalPolicies: "appData/getGlobalPolicies",
      updateGlobalPolicy: "appData/updateGlobalPolicy",
      delete: "appData/deleteGlobalPolicy",
    }),
    async filter() {
      this.currentPage = 1;
      await this.search();
    },
    async search() {
      this.isBusy = true;
      await this.fetchPaginatedData();
      this.isBusy = false;
    },
    createGlobalPolicy() {
      this.globalPolicyCreateModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("global-policy-create-modal");
      });
    },
    editGlobalPolicy(globalPolicy) {
      this.globalPolicy = globalPolicy;
      this.globalPolicyEditModalCount += 1;
      this.visible = true;
      this.allowEdit = true;
      this.$nextTick(() => {
        this.$bvModal.show("global-policy-edit-modal");
      });
    },
    viewGlobalPolicy(globalPolicy) {
      this.globalPolicy = globalPolicy;
      this.globalPolicyEditModalCount += 1;
      this.visible = true;
      this.allowEdit = false;
      this.$nextTick(() => {
        this.$bvModal.show("global-policy-edit-modal");
      });
    },

    //HARD DELETE
    async deleteGlobalPolicy(globalPolicy) {
      try {
        this.$swal({
          title:
            "Are you sure?",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            try {
              const res = await this.delete({
                pk: globalPolicy.id,
              });
              if (res.status === 204) {
                this.$swal({
                  title: "Global Policy deleted successfully",
                  icon: "success",
                });
                this.$nextTick(() => {
                  this.search();
                });
                this.$emit("modalClosed");
              }
            } catch (error) {
              this.show = false;
              if (error.response.status == 500) {
                // this.displayError("Kindly make sure this global policy does not have any dependencies");
                this.$swal({
                  title: "Kindly make sure this global policy  does not have any dependencies",
                  icon: "error",
                });
              }
              else {
                this.displayError(error);
              }
            }
          } else {
            this.show = false;
          }
        });
      } catch (error) {
        this.displayError(error);
      }
    },

    // SOFT DELETE
    // deleteGlobalPolicy(globalPolicy) {
    //   try {
    //     this.$swal({
    //       title: "Are you sure? This global policy will be deleted.",
    //       icon: "warning",
    //       showCloseButton: true,
    //       showCancelButton: true,
    //       confirmButtonText: "Confirm",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //         cancelButton: "btn btn-danger ml-1",
    //       },
    //       buttonsStyling: false,
    //     }).then(async (result) => {
    //       if (result.value) {
    //         try {
    //           const res = await this.updateGlobalPolicy({
    //             payload: {
    //               is_delete: true,
    //               updated_by: this.getLoggedInUser.id,
    //             },
    //             pk: globalPolicy.id,
    //           });
    //           let response_string = "GlobalPolicy deleted successfully";
    //           let response_msg = response_string;
    //           if (res.data.response_msg) {
    //             response_msg = response_msg.concat(res.data.response_msg);
    //           }
    //           if (res.status === 200) {
    //             this.$swal({
    //               title: response_msg,
    //               icon: "success",
    //             });
    //             this.$nextTick(() => {
    //               this.fetchPaginatedData();
    //             });
    //             this.$emit("modalClosed");
    //           }
    //         } catch (error) {
    //           this.show = false;
    //           this.displayError(error);
    //         }
    //       } else {
    //         this.show = false;
    //       }
    //     });
    //   } catch (error) {
    //     this.displayError(error);
    //   }
    // },
    async onModalClosed() {
      await this.fetchPaginatedData();
      this.visible = false;
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        if (this.nameFilter) {
          tempParams["name"] = this.nameFilter;
        }
        if (this.codeFilter) {
          tempParams["code"] = this.codeFilter;
        }
        if (this.valueFilter) {
          tempParams["value"] = this.valueFilter;
        }
        const res = await this.getGlobalPolicies(tempParams);
        this.globalpolicies = res.data.results;
        this.totalDataLength = res.data.count;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>
  
  <style></style>
  