<template>
    <b-modal id="global-policy-create-modal" title="Global Policy Create Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Create Global Policy</h2>
      </template>
      <validation-observer ref="globalpolicyCreateFormValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Policy Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                      :rules="{ required, regex: /^[0-9A-Z-()#&''\s]*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Policy Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="code" label-for="code">
                <template #label>
                  Policy Code <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="code"
                      :rules="{ required, regex: /^[0-9A-Z-_()#&''\s]*$/i }">
                      <b-form-input id="code" v-model="code" :state="errors.length > 0 ? false : null"
                      placeholder="Policy code" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
         <b-row>
            <b-col>
                <b-form-group label="value" label-for="value">
                <template #label>
                    Policy Value <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="value"
                    :rules="{ required, regex: /^([0-9]){1,4}$/i }">
                    <b-form-input id="value" v-model="value"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Policy value" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
          <b-row>
            <b-col>
              <b-form-group label="description" label-for="description">
                <template #label>
                  Policy Description <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="description"
                      :rules="{ required, regex: /^[0-9A-Z-,.()#&''\s]*$/i }">
                      <b-form-input id="description" v-model="description" :state="errors.length > 0 ? false : null"
                      placeholder="Policy description" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
            <b-form-group class="text-right">
                <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                    Create
                </b-button>
            </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        name: "",
        value: "",
        code: "",
        description: "",
        required,
       
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        createGlobalPolicy: "appData/createGlobalPolicy",
      }),
      async validationForm() {
        const success = await this.$refs.globalpolicyCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
          let dataToInsert = {
            name: this.name,
            code: this.code,
            value: this.value,
            description: this.description,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));
          const res = await this.createGlobalPolicy(formData);
          if (res.status === 201) {
            this.$swal({
              title: "Global Policy created successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("global-policy-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },

      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  